import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import capitalize from 'lodash/capitalize'

import { updateConditionActiveLearningPriorityAction } from '../../hasura/slices/conditions'
import { UsersState, slackMessageAction, usersSelector } from '../../hasura/slices/users'

interface Props {
  value: number
  conditionId: number
  conditionName: string
  species: string
}

const PRIORITY_LEVELS = { 0: '', 1: 'low', 2: 'high' }

export default function Slider(props: Props) {
  const dispatch = useDispatch()

  const { accessToken, user }: UsersState = useSelector(usersSelector)

  const handleUpdatedPriority = (value: number) => {
    const msg = `${props.conditionName} (${props.species}) ${
      // @ts-ignore
      value ? `priority updated to ${PRIORITY_LEVELS[value]}` : 'deprioritized'
    } by ${user?.display_name}.`
    dispatch(slackMessageAction('engineering', msg))
    dispatch(updateConditionActiveLearningPriorityAction(accessToken, props.conditionId, value, props.species))
  }

  const progress = props.value === 2 ? 100 : props.value === 1 ? 50 : 0

  return (
    <div
      key={props.conditionId}
      style={{ borderRadius: '20px' }}
      className="d-flex justify-content-between border position-relative mr-3"
    >
      <div style={{ borderRadius: '20px', width: `${progress}%` }} className="position-absolute h-100 bg--primary" />

      {Object.keys(PRIORITY_LEVELS).map((option) => {
        const parsed = parseInt(option, 10)
        const highlight = props.value >= parsed

        return (
          <div className="text-center position-relative">
            <div
              key={option}
              onClick={() => handleUpdatedPriority(parsed)}
              className={`pointer rounded rounded-circle ${highlight ? '' : ''}`}
              style={{ height: '12px', width: '12px' }}
            />
            <p
              style={{ marginTop: '12px' }}
              className={`text-xxs semibold ${props.value == parsed ? 'text--primary' : 'text--gray3'} ls-sm position-absolute top-0`}
            >
              {
                // @ts-ignore
                capitalize(PRIORITY_LEVELS[option])
              }
            </p>
          </div>
        )
      })}
    </div>
  )
}
