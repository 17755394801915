import React, { useMemo } from 'react'
import capitalize from 'lodash/capitalize'
import moment from 'moment'
import { Button, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap'
import { useTable, useSortBy } from 'react-table'

import Slider from './Slider'
import { Species } from '../../lib/helpers'
import { Table, Header, HeaderCell, TextCell, NarrowCell, isColumn } from '../common/Table'

// @ts-ignore
import dots from '../../lib/images/dots.svg'

// @ts-ignore
import aiConfig from '../../../aiConfig.json'

const defaultColumn: any = {
  Cell: TextCell,
}

interface Props {
  data: ConditionData[]
  species: Species
  handleUpdateTrianAndDeploy: (conditionId: number, trainAndDeploy: boolean, species: string) => void
}

export interface ConditionData {
  activeLearningCatPriority: number
  activeLearningDogPriority: number
  lastTrainedAtCat?: string
  lastTrainedAtDog?: string
  trainAndDeployCat: boolean
  trainAndDeployDog: boolean
  lastDeployedAtCat?: string
  lastDeployedAtDog?: string
  id: number
  ml_name: string | null
  name: string
  precision: string
  precisionAccuracy: number
  recall: string
  recallAccuracy: number
  specificity: string
  specificityAccuracy: number
  wandbLink: string
  consultationPredictions: number
}

export const modelExists = (species: Species, condition?: string | null) =>
  Object.keys(aiConfig[species])
    .concat(...Object.keys(aiConfig[`${species}-yolo`] || {}))
    .concat(...Object.keys(aiConfig[`combined-yolo`] || {}))
    .includes(condition || '')

export default function ConditionsTable(props: Props) {
  const { data } = props

  const columns = useMemo(
    () => [
      {
        Header: 'Condition',
        accessor: (c: ConditionData) => c,
        sortType: (a: any, b: any) => a.original.name - b.original.name,
        sortDescFirst: true,
        Cell: (c: { value: ConditionData }) => (
          <p className="text-m m-0">
            {modelExists(props.species, c.value.ml_name) ? '✅' : ''} {c.value.name}
          </p>
        ),
      },
      {
        Header: 'Sensitivity',
        accessor: (c: ConditionData) => c,
        sortType: (a: any, b: any) => a.original.recallAccuracy - b.original.recallAccuracy,
        sortDescFirst: true,
        Cell: (c: { value: ConditionData }) => {
          if (c.value.recall === '-') return null

          // @ts-ignore
          const isLow = c.value.recallAccuracy < 50
          // @ts-ignore
          const isHigh = c.value.recallAccuracy > 65
          return (
            <div className="d-flex align-items-center">
              <p
                className={`text-m m-0 min-width-50px text-center
                ${isLow || isHigh ? 'text--white rounded width-fit-content px-2' : ''}
                ${isLow ? 'bg--danger' : isHigh ? 'bg--success' : ''}
              `}
              >
                {c.value.recallAccuracy}%
              </p>
              <p className="text--gray3 ml-1 mb-0">{c.value.recall}</p>
            </div>
          )
        },
      },
      {
        Header: 'Specificity',
        accessor: (c: ConditionData) => c,
        sortType: (a: any, b: any) => a.original.specificityAccuracy - b.original.specificityAccuracy,
        sortDescFirst: true,
        Cell: (c: { value: ConditionData }) => {
          if (c.value.specificity === '-') return null

          // @ts-ignore
          const isLow = c.value.specificityAccuracy < 50
          // @ts-ignore
          const isHigh = c.value.specificityAccuracy > 65
          return (
            <div className="d-flex align-items-center">
              <p
                className={`text-m m-0 min-width-50px text-center
                ${isLow || isHigh ? 'text--white rounded width-fit-content px-2' : ''}
                ${isLow ? 'bg--danger' : isHigh ? 'bg--success' : ''}
              `}
              >
                {c.value.specificityAccuracy}%
              </p>
              <p className="text--gray3 ml-1 mb-0">{c.value.specificity}</p>
            </div>
          )
        },
      },
      {
        Header: 'Precision',
        accessor: (c: ConditionData) => c,
        sortType: (a: any, b: any) => a.original.precisionAccuracy - b.original.precisionAccuracy,
        sortDescFirst: true,
        Cell: (c: { value: ConditionData }) => {
          if (c.value.precision === '-') return null

          // @ts-ignore
          const isLow = c.value.precisionAccuracy < 50
          // @ts-ignore
          const isHigh = c.value.precisionAccuracy > 65
          return (
            <div className="d-flex align-items-center">
              <p
                className={`text-m m-0 min-width-50px text-center
                ${isLow || isHigh ? 'text--white rounded width-fit-content px-2' : ''}
                ${isLow ? 'bg--danger' : isHigh ? 'bg--success' : ''}
              `}
              >
                {c.value.precisionAccuracy}%
              </p>
              <p className="text--gray3 ml-1 mb-0">{c.value.precision}</p>
            </div>
          )
        },
      },
      {
        Header: '# Consults',
        accessor: (c: ConditionData) => c.consultationPredictions.toLocaleString(),
        sortType: (a: any, b: any) => a.original.consultationPredictions - b.original.consultationPredictions,
        sortDescFirst: true,
      },
      {
        Header: 'Deployed',
        accessor: (c: any) =>
          c[`lastDeployedAt${capitalize(props.species)}`] ? moment(c[`lastDeployedAt${capitalize(props.species)}`]).fromNow() : '',
      },
      {
        Header: 'Priority',
        accessor: (c: ConditionData) => c,
        sortDescFirst: true,
        sortType: (a: { original: ConditionData }, b: { original: ConditionData }) => {
          return (
            // @ts-ignore
            a.original[`activeLearning${capitalize(props.species)}Priority`] -
            // @ts-ignore
            b.original[`activeLearning${capitalize(props.species)}Priority`]
          )
        },
        Cell: (c: { value: ConditionData }) => (
          <div>
            <Slider
              // @ts-ignore
              value={c.value[`activeLearning${capitalize(props.species)}Priority`]}
              conditionId={c.value.id}
              conditionName={c.value.name}
              species={props.species}
            />
          </div>
        ),
      },
      {
        Header: 'Train',
        accessor: (c: ConditionData) => c,
        Cell: (c: { value: ConditionData }) => {
          // @ts-ignore
          const isOn = Boolean(c.value[`trainAndDeploy${capitalize(props.species)}`])
          return (
            <div>
              <Button
                onClick={() => props.handleUpdateTrianAndDeploy(c.value.id, !isOn, props.species)}
                size="sm"
                color="primary"
                outline={!isOn}
              >
                {isOn ? 'Triggered' : 'Trigger'}
              </Button>
            </div>
          )
        },
      },
      {
        Header: 'Actions',
        accessor: (c: ConditionData) => c,
        Cell: (c: { value: ConditionData }) => {
          return (
            <div>
              <UncontrolledDropdown className="dropdown-menu-dots" onClick={(e: any) => e.stopPropagation()}>
                <DropdownToggle caret>
                  <img className="icon-s ml-2 mb-1" src={dots} />
                </DropdownToggle>

                <DropdownMenu right>
                  <DropdownItem header>
                    <p className="m-0 text-s bold text--black">More actions</p>
                  </DropdownItem>

                  <DropdownItem text>
                    <p
                      onClick={() => window.open(`/condition?id=${c.value.id}&species=${props.species}`, '_blank')}
                      className="m-0 text-s regular pointer"
                    >
                      Images
                    </p>
                  </DropdownItem>

                  <DropdownItem text>
                    <p onClick={() => window.open(`/feed?c=${c.value.id}`, '_blank')} className="m-0 text-s regular pointer">
                      Feed
                    </p>
                  </DropdownItem>

                  <DropdownItem text>
                    <p onClick={() => window.open(c.value.wandbLink, '_blank')} className="m-0 text-s regular pointer">
                      Weights & Biases
                    </p>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          )
        },
      },
    ],
    [props.species]
  )

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      // @ts-ignore
      columns,
      data,
      defaultColumn,
    },
    useSortBy
  )

  const colSpan = (data: any) => (isColumn(data, 'Condition') ? 2 : 1)

  return (
    <Table style={{ maxWidth: '1300px' }} className="w-100" cellSpacing={0} {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column: any, idx: number) => (
              <HeaderCell
                noBorder
                key={idx}
                {...column.getHeaderProps(
                  column.getSortByToggleProps({
                    style: {
                      opacity: ['Actions'].includes(column.Header) && 0,
                    },
                  })
                )}
                colSpan={colSpan(column)}
              >
                {column.Header && <Header>{column.render('Header')}</Header>}
              </HeaderCell>
            ))}
          </tr>
        ))}
      </thead>

      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row)

          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell: any, idx: number) => {
                return (
                  <NarrowCell key={idx} {...cell.getCellProps()} colSpan={colSpan(cell.column)}>
                    {cell.render('Cell')}
                  </NarrowCell>
                )
              })}
            </tr>
          )
        })}
      </tbody>
    </Table>
  )
}
