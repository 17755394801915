import React from 'react'
import { Badge } from 'reactstrap'

export enum TimeFrame {
  TwoWeeks = '2W',
  OneMonth = '1M',
  TwoMonths = '2M',
  ThreeMonth = '3M',
  SixMonth = '6M',
}

const TIME_FRAMES = [TimeFrame.OneMonth, TimeFrame.ThreeMonth, TimeFrame.SixMonth]

interface Props {
  selectedTimeFrame: TimeFrame
  setSelectedTimeFrame: (t: TimeFrame) => void
  options?: TimeFrame[]
}

export default function TimeFrames(props: Props) {
  return (
    <div className="d-flex gap-10px">
      {(props.options || TIME_FRAMES).map((t, idx) => (
        <Badge
          className={`
        text-xs border-radius-5px pointer
        ${props.selectedTimeFrame === t ? 'bg--primary text--white' : 'bg--white text--primary'}
      `}
          key={idx}
          onClick={() => props.setSelectedTimeFrame(t)}
          outline={props.selectedTimeFrame === t}
          size="sm"
        >
          {t}
        </Badge>
      ))}
    </div>
  )
}
